import React, { useEffect } from "react"
import { Controller } from "react-hook-form"
import { Input } from "rsuite"
import { useFormContext } from "react-hook-form"

const FormHiddenInput = ({ name }) => {
  const { resetField, getValues } = useFormContext()

  useEffect(() => {
    return () => {
      resetField(name, {
        defaultValue: "",
      })
    }
  }, [name, resetField])

  return (
    <Controller
      name={name}
      render={({ field }) => (
        <Input
          name={field.name}
          type="hidden"
          value={field?.value || getValues(`${name}`) || ""}
        />
      )}
    />
  )
}

export default FormHiddenInput
