import { Controller, useFormContext } from "react-hook-form"
import DynamicFormItems from "./DynamicFormItems"

/*
* @typedef {object} props
* @property {object} item
* @property {string} errorMessage
* @property {string} name
* @property {string} formPath
* @property {string} conditionName
* @property {string} hiddenName
* @property {boolean} required
* @property {boolean} ascSort
* @property {boolean} isFirstDefault
* 
* @description isFirstDefault - This parameter will change first value to default value on ListParameter and assign '0' to NumericParameter.
*/
const DynamicFormItemsManager = ({
  item,
  errorMessage,
  name,
  formPath,
  conditionName,
  hiddenName,
  required,
  ascSort,
  isFirstDefault,
}) => {
  const { control } = useFormContext()

  return (
    <>
      <Controller
        name={name}
        control={control}
        rules={{
          required: !!required ? "Bu alan boş bırakılamaz" : false,
        }}
        render={({ field: { name, onChange } }) => {
          const props = {
            item,
            name,
            placeholder: item?.Title || "Bir değer giriniz",
            onChange,
            formPath,
            conditionName,
            hiddenName,
            ascSort,
            isFirstDefault,
          }
          return DynamicFormItems[item?.Type || "NumericParameter"](props) || DynamicFormItems["NumericParameter"](props)
        }}
      />
      {errorMessage && required && <p className="text-red-500 text-xs py-1">{errorMessage}</p>}
    </>
  )
}

export default DynamicFormItemsManager
