import React from "react"

import { Icon } from "components"
import { ReservationStatus } from "constants/index";

function StatusText({ status, className, text, cancelReason, ...props }) {

  const content = ReservationStatus[status]
  return (
    <div className="flex flex-col">
      <div
        className={`${content?.textColor}
        } flex items-center gap-1.5`}
        {...props}
      >
        {content?.icon && (
          <Icon
            name={content?.icon}
            size={16}
            className={content?.iconColor}
            color={"#4CAF50"}
          />
        )}
        <p className={`${content?.textColor} ml-1.5`}>{content?.text}</p>
      </div>
    </div>
  )
}

export { StatusText }
