/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react"
import {useDispatch} from "react-redux"
import {ReactSortable} from "react-sortablejs";

// components
import { Dropdown, Whisper } from "rsuite"
import { ErrorMessage, Icon, TabList } from "components"
import {
  HEADER,
  ICON_LIST,
  MEDIA_LIST, NEW_REGISTER_CODE,
  TAB_SLIDER,
  TEXT_HTML,
  TRAVEL_PLANNER_HOTEL,
  TRAVEL_PLANNER_TOUR,
  TRAVEL_PLANNER_TRANSFER_AGENCY,
  TRAVEL_PLANNER_TRANSFER_CORPORATE,
  TRAVEL_PLANNER_FLIGHT,
  TRAVEL_PLANNER_CAR_RENTAL_AGENCY,
  TRAVEL_PLANNER_CAR_RENTAL_CORPORATE, ATOM_PAGE,
} from "../../Enums";

//layouts
import Slider from "../InnerSideBars/Slider"
import IconList from "../InnerSideBars/IconList"
import MediaList from "../InnerSideBars/MediaList"
import TextBlock from "../InnerSideBars/TextBlock"
import TravelPlanner from "../InnerSideBars/TravelPlanner"
import Header from "../InnerSideBars/Header"

// utils
import getSelectedIndex from "utils/getSelectedIndex"
import getInfoFromChildren from "utils/getInfoFromChildren"

//skeletons
import {BlockListLoadingSkeleton, ReadyBlocksLoadingSkeleton} from "skeletons/PageManager"

// saga requests
import {getStrategySucceed} from "redux/actions/strategy";

//helpers
import {array_move} from "helpers/ArrayMove";

const Blocks = (props) => {
  let {
    templates,
    strategies,
    editLayouts,
    setEditLayouts,
    EditBlock,
    setEditBlock,
    ActiveCategory,
    setActiveCategory,
    blockListTab,
    setBlockListTab,
    blockListDdownOpen,
    setBlockListDdownOpen,
    AddNewBlockHandleClick,
  } = props

  const dispatch = useDispatch()
  const isTPComponent = editLayouts?.[TRAVEL_PLANNER_HOTEL] || editLayouts?.[TRAVEL_PLANNER_TOUR]
    || editLayouts?.[TRAVEL_PLANNER_TRANSFER_AGENCY]|| editLayouts?.[TRAVEL_PLANNER_TRANSFER_CORPORATE]
    || editLayouts?.[TRAVEL_PLANNER_FLIGHT] || editLayouts?.[TRAVEL_PLANNER_CAR_RENTAL_AGENCY]
    || editLayouts?.[TRAVEL_PLANNER_CAR_RENTAL_CORPORATE]

  const tpType = editLayouts?.[TRAVEL_PLANNER_HOTEL] ? TRAVEL_PLANNER_HOTEL :
    editLayouts?.[TRAVEL_PLANNER_TOUR] ?  TRAVEL_PLANNER_TOUR :
    editLayouts?.[TRAVEL_PLANNER_TRANSFER_AGENCY] ? TRAVEL_PLANNER_TRANSFER_AGENCY :
    editLayouts?.[TRAVEL_PLANNER_TRANSFER_CORPORATE] ? TRAVEL_PLANNER_TRANSFER_CORPORATE :
    editLayouts?.[TRAVEL_PLANNER_FLIGHT] ? TRAVEL_PLANNER_FLIGHT :
    editLayouts?.[TRAVEL_PLANNER_CAR_RENTAL_AGENCY] ? TRAVEL_PLANNER_CAR_RENTAL_AGENCY :
    editLayouts?.[TRAVEL_PLANNER_CAR_RENTAL_CORPORATE] ? TRAVEL_PLANNER_CAR_RENTAL_CORPORATE :
    undefined

  const BlockTabs = [
    { label: "Blok Listesi" },
    { label: "Hazır Bloklar" }
  ]

  const templateList = templates?.list?.result
  const templateListLoading = templates?.loading
  const templateListError = templates?.error
  const getStrategyListResult = strategies?.list?.result
  const getStrategyListLoading = strategies?.loading
  const getStrategyListError = strategies?.error

  //states
  const [BlockList, setBlockList] = useState([])
  const [BlockListUpdated, setBlockListUpdated] = useState(false)

  // Func
  const blockListChanged = (e) => {
    let selectedIndex = getSelectedIndex(e)
    setBlockListTab(selectedIndex)
  }

  const blockListDropDownToggle = (index, show) => {
    setBlockListDdownOpen({
      index,
      show,
    })
  }

  const renderBlockListDropdown = ({ onClose, left, top, className }, ref, index, block) => {

    const listLenght = BlockList.length
    const handleSelect = (eventKey) => {
      if (eventKey) {
        onClose()
        let list
        switch (eventKey) {
          case "edit":
            setEditLayouts({
              [block?.name]: true,
              info: block,
              rejectPreviewRender: true,
            })
            break
          case "move-up":
            list = array_move(BlockList, index, index-1)
            break
          case "move-down":
            list = array_move(BlockList, index, index+1)
            break
          case "delete":
            getStrategyListResult?.children?.splice(index, 1);
            list = getStrategyListResult?.children
            break
          default:
            break
        }

        const doDispatch = eventKey !== "edit"
        if (doDispatch){
          dispatch(getStrategySucceed({
            result: {
              ...getStrategyListResult,
              children: list
            }
          }))
        }
      }
    }
    return (
      <Dropdown.Menu
        ref={ref}
        className={`${className} min-w-[190px] max-w-[240px]`}
        style={{ left, top }}
        onSelect={handleSelect}
      >
        <Dropdown.Item className="font-medium py-2.5 px-4 cursor-default !bg-white !text-gray-800 hover:!bg-white hover:text-gray-800">
          Seçenekler
        </Dropdown.Item>
        <hr />
        <Dropdown.Item
          disabled={index === 0}
          className="flex gap-3 items-center py-2.5 px-4"
          eventKey="move-up"
        >
          <Icon
            name="arrow-up"
            size={16}
            color={index === 0 ? "#c5c6c7" : "#1D2939"}
          />
          <p>Yukarı Taşı</p>
        </Dropdown.Item>
        <Dropdown.Item
          disabled={listLenght - 1 === index}
          className="flex gap-3 items-center py-2.5 px-4"
          eventKey="move-down"
        >
          <Icon
            name="arrow-down"
            size={16}
            color={listLenght - 1 === index ? "#c5c6c7" : "#1D2939"}
          />
          <p>Aşağı Taşı</p>
        </Dropdown.Item>
        <Dropdown.Item
          className="flex gap-3 items-center py-2.5 px-4"
          eventKey="edit"
        >
          <Icon
            name="edit-2"
            size={16}
            color="#1D2939"
          />
          <p>Düzenle</p>
        </Dropdown.Item>
        {/*<Dropdown.Item
          disabled={true}
          className="flex gap-3 items-center py-2.5 px-4"
          eventKey={1}
        >
          <Icon
            name="copy"
            size={16}
            color="#1D2939"
          />
          <p>Kopyala</p>
        </Dropdown.Item>*/}
        <Dropdown.Item
          className="flex gap-3 items-center py-2.5 px-4"
          eventKey="delete"
        >
          <Icon
            name="trash"
            size={16}
            color="#1D2939"
          />
          <p>Sil</p>
        </Dropdown.Item>
      </Dropdown.Menu>
    )
  }

  const addComponentToBlockList = (block) => {
    setEditLayouts({
      [block.name]: true,
      info: block,
      code: NEW_REGISTER_CODE(),
      virtualBlock: true,
      order: AddNewBlockHandleClick
    })
  }

  const blockListSortingChanged = () => {
    setBlockListUpdated(true)
  }

  // effects
  useEffect(() => {
    if (BlockList && BlockListUpdated) {
      setBlockListUpdated(false)
      dispatch(getStrategySucceed({
        result: {
          ...getStrategyListResult,
          children : BlockList
        }
      }))
    }
  }, [BlockList, BlockListUpdated])

  useEffect(() => {
    if (getStrategyListResult) {
      setBlockList(getInfoFromChildren(getStrategyListResult))
    } else {
      setBlockList([])
    }
  }, [getStrategyListResult])

  return (
    <div className="flex flex-col gap-4 h-full">
      <TabList
        onChange={blockListChanged}
        tabs={BlockTabs}
        tabClassName="w-full text-center text-gray-600 p-2 border-r last:border-r-0 hover:bg-gray-50 transition duration-400"
        wrapperClassName="flex shrink-0 border rounded-lg border-gray-200 overflow-hidden"
        //itemActiveClass="!text-gray-800 !bg-blue-50"
        activeTab={blockListTab}
      />
      {blockListTab === 0 && (
        <>
          {getStrategyListError && !getStrategyListLoading && (
            <ErrorMessage className="mt-1">Blok listesi alınırken bir sorun ile karşılaşıldı</ErrorMessage>
          )}
          <div className="border rounded-lg border-gray-200 overflow-auto">

            <div
              className={`group relative flex items-center capitalize gap-2 py-4 px-3 border-b border-gray-200 select-none last:border-0 hover:transition hover:bg-gray-100`}
            >
              <div
                className={`inline-flex items-center transition-all duration-200 proper w-0 overflow-hidden`}
              ></div>
              <Icon
                name="image"
                size={14}
                color="#1D2939"
                className="shrink-0"
              />
              <p className="mr-auto text-sm text-gray-800 truncate">Header</p>
            </div>
            {getStrategyListLoading && !BlockList.length && (
              <>
                <BlockListLoadingSkeleton />
                <BlockListLoadingSkeleton />
              </>
            )}

            {!getStrategyListLoading && BlockList && (
                <ReactSortable
                  onUpdate={blockListSortingChanged}
                  tag="div" list={BlockList} setList={setBlockList}>
                  {BlockList.map((block, index) => {
                    if (block?.name === ATOM_PAGE) return <div className="hidden" key={block?.code} />
                    //let lockedItem = block?.name !== "Header" && block?.name !== "Footer"
                      return (
                        <div
                          key={block?.code}
                          className={`group relative flex items-center capitalize gap-2 py-4 px-3 border-b border-gray-200 select-none last:border-0 hover:cursor-move hover:transition hover:bg-gray-100`}
                        >
                          <div
                            className={`inline-flex items-center transition-all duration-200 proper w-0 overflow-hidden group-hover:w-[14px]`}
                          >
                            <Icon
                              className="min-w-[14px]"
                              name="drag"
                              size={14}
                              color="#1D2939"
                            />
                          </div>
                          <Icon
                            name="image"
                            size={14}
                            color="#1D2939"
                            className="shrink-0"
                          />
                          <p className="mr-auto text-sm text-gray-800 truncate">{block?.visual?.settings?.name || block?.name}</p>

                          <Whisper
                            placement="bottomEnd"
                            trigger="click"
                            className="group-hover:opacity-100 opacity-0"
                            speaker={({ onClose, left, top, className }, ref) =>
                              renderBlockListDropdown({ onClose, left, top, className }, ref, index, block)
                            }
                            onOpen={() => blockListDropDownToggle(index, true)}
                            onClose={() => blockListDropDownToggle(index, false)}
                          >
                            <div className="cursor-pointer">
                              <Icon
                                name="more-vertical"
                                size={14}
                                className="!duration-[100ms]"
                                color={blockListDdownOpen?.index === index && blockListDdownOpen?.show ? "#2E90FA" : "#1D2939"}
                              />
                            </div>
                          </Whisper>
                        </div>
                      )
                    })}
                </ReactSortable>
              )}
          </div>

          {!BlockList && !getStrategyListLoading && !getStrategyListError && (
            <div>Henüz eklenmiş bir blok bulunamadi. Aşağıdaki blok ekle butonuna tıklayarak devam edebilirsiniz</div>
          )}

          <button
            disabled={getStrategyListError || getStrategyListLoading}
            className="btn sm outline shrink-0 mb-10"
            onClick={(e) => setBlockListTab(1)}
          >
            Blok ekle
          </button>
        </>
      )}

      {blockListTab === 1 && (
        <div className="prepared-blocks-area flex flex-col overflow-auto -mr-5 pr-5 pb-8 gap-5">
          {templateListError && !templateListLoading && (
            <ErrorMessage className="mt-1">Component listesi alınırken bir sorun ile karşılaşıldı </ErrorMessage>
          )}
          {templateListLoading && !templateList && (
            <>
              <ReadyBlocksLoadingSkeleton />
            </>
          )}

          {!templateListError &&
            !templateListLoading &&
            templateList &&
            templateList.map((block, index) => {
              return (
                <div
                  key={index}
                  className={"list-item"}
                >
                  <p className={"text-sm font-medium text-gray-800 capitalize"}>{block.name}</p>
                  <div className={"group relative border border-gray-200 rounded-lg mt-2"}>
                    <div className="hover-overlay flex items-center justify-center h-full w-full absolute bg-gray-300/[.06] opacity-0 rounded-lg index-10 transition group-hover:opacity-100">
                      <button
                        className={"btn secondary xs"}
                        type="button"
                        disabled={editLayouts}
                        onClick={(e) => addComponentToBlockList(block)}
                      >
                        <Icon
                          name={"plus"}
                          size={20}
                          color={"#2E90FA"}
                        />
                        <span>Ekle</span>
                      </button>
                    </div>
                    <div className={"image-area p-2"}>
                      <img
                        className={"w-full"}
                        src={(block?.media && block?.media[0]?.path) || `https://placehold.it/280x100?text=${block.name}`}
                        alt={"block img"}
                      />
                    </div>
                  </div>
                </div>
              )
            })}
        </div>
      )}

      {editLayouts?.[TAB_SLIDER] && (
        <Slider
          show={editLayouts[TAB_SLIDER]}
          activeLayout={editLayouts}
          setEditLayouts={setEditLayouts}
          ActiveCategory={ActiveCategory}
          setActiveCategory={setActiveCategory}
          EditBlock={EditBlock}
          setEditBlock={setEditBlock}
          setEditBlocks={setEditLayouts}
        />
      )}
      {editLayouts?.[ICON_LIST] && (
        <IconList
          show={editLayouts?.[ICON_LIST]}
          activeLayout={editLayouts}
          setEditLayouts={setEditLayouts}
        />
      )}
      {editLayouts?.[MEDIA_LIST] && (
        <MediaList
          show={editLayouts?.[MEDIA_LIST]}
          activeLayout={editLayouts}
          setEditLayouts={setEditLayouts}
          EditBlock={EditBlock}
          setEditBlock={setEditBlock}
          setEditBlocks={setEditLayouts}
        />
      )}
      {editLayouts?.[TEXT_HTML] && (
        <TextBlock
          show={editLayouts?.[TEXT_HTML]}
          activeLayout={editLayouts}
          setEditLayouts={setEditLayouts}
        />
      )}
      {isTPComponent && (
        <TravelPlanner
          show={isTPComponent}
          activeLayout={editLayouts}
          setEditLayouts={setEditLayouts}
          travelPlannerType={tpType}
        />
      )}
      {editLayouts?.[HEADER] && (
        <Header
          show={editLayouts?.[HEADER]}
          activeLayout={editLayouts}
          setEditLayouts={setEditLayouts}
        />
      )}
    </div>
  )
}

export default Blocks
