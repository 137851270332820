import tripy from "pages/B2cDemoPage/LivePreview/img/tripy.png"
import DISCOVER from "pages/B2cDemoPage/LivePreview/img/DISCOVER.png"
import GoTrip from "pages/B2cDemoPage/LivePreview/img/GoTrip.png"
import DisplaySettings from "../pages/Setup/Steps/DisplaySettings"
import SetLogoAndThemeColor from "../pages/Setup/Steps/SetLogoAndThemeColor"
//import ReservationPolicy from "../pages/Setup/Steps/ReservationPolicy"
//import Contracts from "../pages/Setup/Steps/Contracts"
//import Loading from "../pages/Setup/Steps/Loading"
import Preview from "../pages/Setup/Steps/Preview"

export const evaGlobalImageUrl = "https://gordiosdatatest.blob.core.windows.net/files/"

export const ProductTypes = {
  Hotel: 1,
  Tour: 2,
  Flight: 3,
  Transfer: 4,
}

export const DomainTypes = [
  {
    value: 1,
    label: "Otel",
  },
  {
    value: 2,
    label: "B2C",
  },
  {
    value: 3,
    label: "Corporate",
  },
]

export const BookingConfirmationStatus = [
  {
    text: "None",
    value: 0,
  },
  {
    text: "Talep Bekleniyor",
    value: 1,
  },
  {
    text: "Talep Onaylandı",
    value: 2,
  },
  {
    text: "Talep Reddedildi",
    value: 3,
  },
  {
    text: "Konfirme Bekleniyor",
    value: 4,
  },
  {
    text: "Konfirme Onaylandı",
    value: 5,
  },
  {
    text: "Konfirme Reddedildi",
    value: 6,
  },
  {
    text: "İptal Konfirmesi Bekleniyor",
    value: 7,
  },
  {
    text: "İptal Konfirmesi Onaylandı",
    value: 8,
  },
  {
    text: "İptal Konfirmesi Reddedildi",
    value: 9,
  },
  {
    text: "Rezervasyon Bekleniyor",
    value: 10,
  },
  {
    text: "Rezervasyon Başarısız",
    value: 11,
  },
  {
    text: "Talep Gönderildi",
    value: 12,
  },
  {
    text: "Konfirme Gönderildi",
    value: 13,
  },
  {
    text: "İptal Konfirmesi Gönderildi",
    value: 14,
  },
  {
    text: "Değişiklik Konfirmesi Bekleniyor",
    value: 15,
  },
  {
    text: "Değişiklik Konfirmesi Gönderildi",
    value: 16,
  },
  {
    text: "Değişiklik Konfirmesi Onaylandı",
    value: 17,
  },
  {
    text: "Değişiklik Konfirmesi Reddedildi",
    value: 18,
  },
  {
    text: "Konfirme Manuel Onaylandı",
    value: 19,
  },
]

export const ReservationStatus = [
  {
    text: "Onay Bekliyor",
    status: 0,
    textColor: "!text-warning-500",
    iconColor: "!bg-warning-500",
    icon: "clock"
  },
  {
    text: "Onaylandı",
    status: 1,
    textColor: "!text-green-500",
    iconColor: "!bg-green-500",
    icon: "check"
  },
  {
    text: "Reddedildi",
    status: 2,
    textColor: "!text-error-500",
    iconColor: "!bg-error-500",
    icon: "x"
  },
  {
    text: "Talep İptal edildi",
    status: 3,
    textColor: "!text-error-500",
    iconColor: "!bg-error-500",
    icon: "x"
  },
  {
    text: "Tamamlanmadı",
    status: 4,
    textColor: "!text-warning-500",
    iconColor: "!bg-warning-500",
    icon: "check"
  },
  {
    text: "Değiştirildi",
    status: 5,
    textColor: "!text-blue-500",
    iconColor: "!bg-blue-500",
    icon: "refresh-cw"
  },
  {
    text: "Rezervasyon İptal edildi",
    status: 6,
    textColor: "!text-error-500",
    iconColor: "!bg-error-500",
    icon: "x"
  },
]

export const BookingConfirmationFilters = [
  { filterStatus: [1, 4, 7, 10, 15], textColor: "text-warning-400", bgColor: "bg-warning-50" },
  { filterStatus: [3, 6, 8, 9, 11, 18], textColor: "text-error-500", bgColor: "bg-error-50" },
  { filterStatus: [2, 5, 12, 13, 14, 16, 17, 19], textColor: "text-success-500", bgColor: "bg-success-50" },
]

export const B2CDemoTypes = [
  {
    demoType: {
      label: "Aktif Temam",
      value: 0,
    },
  },
  {
    demoType: {
      label: "tripy",
      value: 1,
    },
    displayName: "Yumuşak Köşeler",
    displaySettings: {
      borderRadius: "8px",
      fontFamily: "Inter",
      linkType: "none",
      buttonColor: "#2E90FA",
      buttonColorHex: "#2E90FA",
      linkColor: "#2E90FA",
      linkColorHex: "#2E90FA",
    },
    logoValues: {
      src: tripy,
      /*"name": "tripy Logo",
      "size": 99541,*/
    },
  },
  {
    demoType: {
      label: "DISCOVER",
      value: 2,
    },
    displayName: "Yuvarlak Köşeler",
    displaySettings: {
      borderRadius: "26px",
      fontFamily: "Nunito",
      linkType: "underline",
      buttonColor: "#FB5050",
      buttonColorHex: "#FB5050",
      linkColor: "#1D2939",
      linkColorHex: "#1D2939",
    },
    logoValues: {
      src: DISCOVER,
      /*"name": "DISCOVER Logo",
      "size": 155398,*/
    },
  },
  {
    demoType: {
      label: "GoTrip",
      value: 3,
    },
    displayName: "Keskin Köşeler",
    displaySettings: {
      borderRadius: "4px",
      fontFamily: "Exo 2",
      linkType: "none",
      buttonColor: "#FFC500",
      buttonColorHex: "#FFC500",
      linkColor: "#174094",
      linkColorHex: "#174094",
    },
    logoValues: {
      src: GoTrip,
      /*"name": "GoTrip Logo",
      "size": 128322,*/
    },
  },
]

export const SetupConfig = {
  steps: {
    Otel: [
      {
        title: "Görünüm",
        description: "Websiteniz için en uygun temayı belirleyin.",
        slug: "displaySettings",
      },
      {
        title: "Logo & Renk",
        description: "Otelinizin logosunu yükleyin ve bir renk seçin.",
        slug: "logoAndThemeColor",
      },
      // {
      //   title: "Rezervasyon Politikası",
      //   description: "İşletme politikanıza uygun rezervasyon deneyimini oluşturabilmek için gerekli ayarları düzenleyin.",
      //   slug: "reservationPolicy",
      // },
      // {
      //   title: "Sözleşmeler",
      //   description: "Otelinizin ve müşterilerinizin yasal haklarını koruyabilmemiz için ilgili metinleri ekleyin.",
      //   slug: "contracts",
      // },
      {
        title: "Ön izleme",
        description: "",
        slug: "preview",
      },
    ],
    B2C: [
      {
        title: "Görünüm",
        description: "Web sitenizin görünümünü ve yazı tipini markanıza uyacak şekilde özelleştirin.",
        slug: "displaySettings",
      },
      {
        title: "Logo & Renk",
        description: "Logonuzu yükleyin ve sitenizin renk ayarlarını yapın.",
        slug: "logoAndThemeColor",
      },
      {
        title: "Ön izleme",
        description: "",
        slug: "preview",
      },
    ],
    Corporate: [
      {
        title: "Görünüm",
        description: "Web sitenizin görünümünü ve yazı tipini markanıza uyacak şekilde özelleştirin.",
        slug: "displaySettings",
      },
      {
        title: "Logo & Renk",
        description: "Logonuzu yükleyin ve sitenizin renk ayarlarını yapın.",
        slug: "logoAndThemeColor",
      },
      {
        title: "Ön izleme",
        description: "",
        slug: "preview",
      },
    ],
  },
  pages: {
    Otel: [DisplaySettings, SetLogoAndThemeColor, Preview], //  ReservationPolicy, Contracts, Loading
    B2C: [DisplaySettings, SetLogoAndThemeColor, Preview],
    Corporate: [DisplaySettings, SetLogoAndThemeColor, Preview],
  },
}

export const ReservationSettings = {
  maxRoomCount: 3,
  childAge: 12,
  childError:
    "Çocuğunuzun ücretsiz konaklayabilmesi için, otele giriş tarihinde 12 yaşından küçük olmalıdır. 12 yaşından büyük misafirimiz için yeniden arama yaparak rezervasyonunuzu tamamlayabilirsiniz.",
  identityNumber: false,
  foreignGuestError:
    "Belirlenen fiyatlar sadece T.C. vatandaşları için geçerlidir. T.C. vatandaşı olmayan misafirlerimiz için check-in anında fiyat farkı talep edilebilir.",
  onlyMan: false,
  onlyManError:
    "Koşullarımız sebebiyle otelimizde konaklamak için 444 0 644 arayabilir veya müşteri hizmetlerimizden yardım isteyebilirsiniz. Yetkililerimiz tarafından size en kısa zamanda dönüş yapılacaktır.",
}

export const PageTypes = {
  Homepage: "0",
  Landingpage: "1",
  /*Search: "2",
  Detail: "3",
  Checkout: "4",*/
}

export const PageState = {
  Pasif: "0",
  Aktif: "1",
  /*Planned: 2,*/
}

export const MediaType = {
  None: 0,
  Image: 1,
  Video: 2,
  Content: 3,
}

export const CarType = [
  "Binek Coupe",
  "Binek Hatchback",
  "Binek Sedan",
  "Crossover WE",
  "Minibüs",
  "Limuzin",
  "Sport",
  "Cabriolet",
  "Suv",
  "Arazi",
  "Limuzin Navi",
  "Premium",
  "PickUp",
  "Binek",
  "Coupe",
  "Karavan RV",
  "Karavan",
  "Kombi",
  "Roadster",
  "Crossover",
  "Ticari Van",
  "Ticari",
  "Kompakt",
  "Kompakt CP",
  "Ekonomi",
  "Eko SUV",
  "Orta seviye",
  "Lüks",
  "Mini",
  "MPV",
  "MPV Otomatik",
  "Sedan",
  "Standart",
  "Station Wagon",
  "Belirtilmemiş",
]

export const TurkishLetters = ["İ","Ş","Ç","Ğ","Ü","Ö","ı","ş","ç","ğ","ü","ö"]
export const insteadOfTurkishLetters = ["I","S","C","G","U","O","i","s","c","g","u","o"] // siralamasi TurkishLetters ile ayni olmalidir
