import React, { useState, createContext } from "react"
import RuleFormItemsCard from "./RuleFormItemsCard"
import { useForm, FormProvider } from "react-hook-form"
import { Grid, Row, Col, Modal, Button } from "rsuite"
import { Card } from "components"
import { FormNameInput, FormStatusSelectBox, FormPrioritySelectBox } from "../FormItems"
import ResultFormItemsCard from "./ResultFormItemsCard"
import { FormToDataSerializer } from "pages/Rules/components/SerializerFunctions/FormToDataSerializer"
import { useRuleMetaDataByRuleTypeId, useAddOrUpdateRuleMutation } from "utils/useRules"
import SuccessModal from "pages/Rules/components/SuccessModal"
import FormFooter from "pages/Rules/components/FormLayouts/FormFooter"
import { useNavigate } from "react-router"
import FormHiddenInput from "../FormItems/FormHiddenInput"
import { ReactComponent as ErrorInfo } from "assets/svg/error-info.svg"

export const RuleFormContext = createContext({
  ruleTypeId: 0,
})

const initFormValue = {
  defaultValues: {
    rule: {
      Name: "",
      Status: "active",
      Priority: 1,
      children: [
        {
          multipleCondition: "and",
          relatedCondition: "and",
          children: [
            {
              conditionSelect: undefined,
            },
          ],
        },
      ],
    },
    result: {},
  },
  shouldUnregister: true,
  mode: "onSubmit",
}

const RuleForm = ({ ruleTypeId, successMessage, successDescription, editInitialValue, id }) => {
  const { mutateAsync, isPending } = useAddOrUpdateRuleMutation()
  const { ruleItems, resultItems } = useRuleMetaDataByRuleTypeId(ruleTypeId)
  const methods = useForm(editInitialValue || initFormValue)

  const [nofiticationModalOpen, setNofiticationModalOpen] = useState(false)
  const [errorModalOpen, setErrorModalOpen] = useState(false)
  const navigate = useNavigate()

  function generateReservationsWithoutApproval(formObject) {
    return {
      ...formObject,
      result: {
        ...formObject.result,
        children:
          formObject?.result?.children?.map((item) => {
            if (item?.children?.length > 0) {
              item?.children?.forEach((subItem) => {
                const prevItem = { ...subItem }
                if (
                  resultItems?.Metadatas?.[prevItem.conditionSelect]?.ParameterDefinations?.some(
                    (item) =>
                      item.Title === RuleForm.PageMeta.reservationRule.spesificCondition_1 ||
                      item.Description === RuleForm.PageMeta.reservationRule.spesificCondition_1,
                  )
                ) {
                  /**
                   * This filter is a spesific field. If you want to learn more information, you can contact with PM's.
                   */
                  Object.keys(prevItem).forEach((key) => {
                    delete subItem[key]
                  })
                  Object.assign(
                    subItem,
                    {
                      BooleanListParameter: !!formObject?.result?.reservationWithoutApproval ? "true" : "false",
                      [`${RuleForm.PageMeta.reservationRule.spesificCondition_1}_Text`]: !!formObject?.result
                        ?.reservationWithoutApproval
                        ? "Evet"
                        : "Hayır",
                    },
                    prevItem,
                  )
                }
              })
            }
            return item
          }) || [],
      },
    }
  }
  function generateResultNumericValues(formObject) {
    return {
      ...formObject,
      result: {
        ...formObject.result,
        children:
          formObject?.result?.children?.map((item) => {
            item.children?.forEach((subItem) => {
              const keys = Object.keys(subItem)
              keys.forEach((key) => {
                if (key.includes("NumericParameter")) {
                  subItem[key] = subItem[key] || "0"
                }
              })
            })
            return item
          }) || [],
      },
    }
  }

  const handleSubmit = (formObject) => {
    let newFormObject = { ...formObject }

    /**
     * This filter is a spesific field. If you want to learn more information, you can contact with PM's.
     * This parameter couldn't be support by backend.
     */
    if (ruleTypeId === RuleForm.PageMeta.reservationRule.ruleTypeId) {
      newFormObject = generateReservationsWithoutApproval(formObject)
    }

    newFormObject = { ...generateResultNumericValues(newFormObject) }

    mutateAsync(
      FormToDataSerializer({
        ruleTypeId,
        formObject: newFormObject,
        ruleItems,
        resultItems,
      }),
    )
      .then((e) => {
        if (e?.data?.errors?.length > 0) {
          setErrorModalOpen(true)
          return
        }
        setNofiticationModalOpen(true)
      })
      .catch(() => {
        setErrorModalOpen(true)
      })
  }

  const handleClickCancel = () => {
    navigate("/rules")
  }

  return (
    <RuleFormContext.Provider value={{ ruleTypeId }}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleSubmit)}>
          {!!id && !!editInitialValue && (
            <FormHiddenInput
              type="hidden"
              name="rule.Id"
            />
          )}
          {/* Form Head Items */}
          <Card
            title="Genel Bilgiler"
            size="sm"
          >
            <Grid fluid>
              <Row>
                <Col
                  xs={24}
                  md={8}
                >
                  <FormNameInput />
                </Col>
                <Col
                  xs={24}
                  md={8}
                >
                  <FormStatusSelectBox methods={methods} />
                </Col>
                <Col
                  xs={24}
                  md={8}
                >
                  <FormPrioritySelectBox methods={methods} />
                </Col>
              </Row>
            </Grid>
          </Card>

          {/* Form Rule Items */}
          <RuleFormItemsCard title={"Koşullar"} />

          {/* Form Result Items */}
          {/* {limitVisible || RuleForm.PageMeta.budgetApprove.ruleTypeId === ruleTypeId ? ( */}
          <ResultFormItemsCard
            defaultOpen={
              !!editInitialValue?.defaultValues?.result?.children?.[0]?.children?.[0]?.conditionSelect ||
              RuleForm.PageMeta.budgetApprove.ruleTypeId === ruleTypeId ||
              RuleForm.PageMeta.searchRule.ruleTypeId === ruleTypeId
            }
            isEditing={!!editInitialValue}
          />

          {/* Form Buttons */}
          <FormFooter
            onCancel={handleClickCancel}
            isLoading={isPending}
          />
        </form>

        <SuccessModal
          open={nofiticationModalOpen}
          setOpen={setNofiticationModalOpen}
          title={successMessage}
          description={successDescription}
          size="stretch"
        />
        <Modal
          size={"xs"}
          open={errorModalOpen}
          className="!mt-0 absolute top-1/2 left-1/2 transform !-translate-x-1/2 !-translate-y-1/2"
          onClose={() => setErrorModalOpen(false)}
        >
          <Modal.Header></Modal.Header>
          <Modal.Body>
            <div className="flex justify-start gap-4 items-center flex-col">
              <ErrorInfo />
              <div className="my-4">
                <p className="font-semibold">Kural {!!id && !!editInitialValue ? "Güncellenemedi" : "Eklenemedi"}</p>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Grid fluid>
              <Row>
                <Col xs={24}>
                  <Button
                    className="w-full btn text-sm"
                    appearance="primary"
                    onClick={() => setErrorModalOpen(false)}
                  >
                    Kapat
                  </Button>
                </Col>
              </Row>
            </Grid>
          </Modal.Footer>
        </Modal>
      </FormProvider>
    </RuleFormContext.Provider>
  )
}

RuleForm.prototypes = {
  ruleTypeId: Number,
  success: {
    title: String,
    description: String,
  },
}

// Formların oluşturulması gereken meta bilgileri.
// @note - İlerleyen süreçte kullanılan veriler olabileceği için alt objeler ile ayrıldı.
RuleForm.PageMeta = {
  // Arama Kuralı
  searchRule: {
    ruleTypeId: 6002,
    successMessage: "Arama Kuralı başarıyla eklendi",
    successDescription: "Yeni bir kural ekleyebilir veya kural listesine dönebilirsiniz",
  },
  // Onay Kuralı
  requestApprove: {
    ruleTypeId: 6003,
    successMessage: "Onay Kuralı başarıyla eklendi",
    successDescription: "Yeni bir kural ekleyebilir veya kural listesine dönebilirsiniz",
  },
  // Bütçe Kuralı
  budgetApprove: {
    ruleTypeId: 6004,
    successMessage: "Bütçe Kuralı başarıyla eklendi",
    successDescription: "Yeni bir kural ekleyebilir veya kural listesine dönebilirsiniz",
  },
  // Rezervasyon Kuralı
  reservationRule: {
    ruleTypeId: 6005,
    successMessage: "Rezervasyon Kuralı başarıyla eklendi",
    successDescription: "Yeni bir kural ekleyebilir veya kural listesine dönebilirsiniz",
    /**
     * This filter is a spesific field. If you want to learn more information, you can contact with PM's.
     * This parameter couldn't be support by backend.
     */
    spesificCondition_1: "Onay Gerektirmeden Rezervasyon Yapsın",
    spesificCondition_2: "Bütçe Aşım Yüzdelik Mi ?",
  },
}

export default RuleForm
