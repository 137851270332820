import React, { useEffect, useMemo } from "react"
import { useRef } from "react"
import { SelectPicker } from "rsuite"
import localization from "localization"
import { useDynamicData } from "utils/useDynamicData"
import FormHiddenInput from "./FormHiddenInput"
import { useFormContext } from "react-hook-form"
import { useUpdateEffect } from "usehooks-ts"

const FormSelectPicker = ({ item, name, onChange, conditionName, hiddenName, ascSort = true, isFirstDefault = false }) => {
  const ref = useRef(null)
  const url = !!item ? item?.Data?.url : ""
  const { data, isLoading } = useDynamicData(url)
  const { setValue, getValues, resetField } = useFormContext()

  let mappedData = useMemo(() => {
    return (
      data?.data?.result?.map((item) => ({
        label: item.text,
        value: `${item.value}`,
        key: `${item.value}`,
      })) || []
    )
  }, [data])

  if (mappedData.length === 0 && !!item?.Items) {
    const itemsArray = Object.entries(item?.Items)

    mappedData =
      itemsArray
        .map((item) => ({
          label: item[1].Title,
          value: `${item[0]}`,
          key: `${item[0]}`,
        }))
        .sort(function (a, b) {
          if (ascSort) {
            if (a.label < b.label) {
              return -1
            }
            if (a.label > b.label) {
              return 1
            }
          }
          return 0
        }) || []
  }

  useUpdateEffect(() => {
    resetField(name, { defaultValue: "" })
  }, [conditionName])

  useEffect(() => {
    if (isFirstDefault && !getValues(name)) {
      setValue(name, mappedData[0]?.value)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div ref={ref}>
      <SelectPicker
        placeholder={item?.Title || "Seçim Yapınız"}
        name={name}
        container={ref.current}
        menuStyle={{ width: ref.current?.clientWidth + 20 }}
        block
        disabled={!mappedData.length}
        searchable={false}
        cleanable={false}
        loading={isLoading}
        data={mappedData || []}
        value={getValues(`${name}`)}
        locale={localization.TR.PickerLocaleType.Picker}
        onChange={
          // @param val: string | number
          (val) => {
            const selectedValue = mappedData.find((item) => item.value === val)
            onChange(val)

            if (item.Type === "BooleanListParameter" && !!hiddenName) {
              setValue(hiddenName, selectedValue.label)
            } else {
              setValue(`${name}_Text`, selectedValue.label)
            }
          }
        }
      />
      <FormHiddenInput
        type="hidden"
        name={item.Type === "BooleanListParameter" && !!hiddenName ? hiddenName : `${name}_Text`}
      />
    </div>
  )
}

export default FormSelectPicker
